html {
  user-select: none;
}

/** Links **/
a {
  text-decoration: none;
  color: currentColor;
}

p > a {
  --color: var(--link-color);
  font-weight: var(--font-weight-header);
}

svg {
  /* DISABLED since it messes up our icons */
  /* shape-rendering: crispEdges;*/
}

/** Definition Lists **/
dl {}

dt {
  font-size: var(--font-size-7);
  font-weight: var(--font-weight-header);
  letter-spacing: var(--letter-spacing-2);
  line-height: var(--line-height-small);
  margin: 0 0 var(--size-extra-small);
  text-transform: uppercase;
}

dd {
  margin: 0 0 var(--size-medium) var(--size-medium);
}

p {
  line-height: var(--line-height-large);
}

.disabled,
.color-disabled {
  pointer-events: none;
  cursor: initial;
  opacity: 0.5;
}

/* See https://developer.mozilla.org/en-US/docs/Web/CSS/cursor */
.cursor-locked {
  cursor: not-allowed;
}

.cursor-help,
.cursor-tooltip {
  cursor: help;
}

.cursor-wait {
  cursor: wait;
}

.display-table {
  display: table;
}

.aspect-ratio-1-1 {
  aspect-ratio: 1/1;
}

.aspect-ratio-4-3 {
  aspect-ratio: 4/3;
}

.aspect-ratio-16-9 {
  aspect-ratio: 16/9;
}

#background-stripes path {
  stroke: var(--white-dark);
}

[data-color-mode="dark"] {
  #background-stripes path {
    stroke: var(--white-transparent);
  }
}

#overlay-stripes {
  path {
    stroke: var(--black-transparent-light);
  }
}

.floorplan-image {
  width: 100%;
  height: 100%;
  object-fit: contain;

  [data-color-mode="light"] & {
     /* make it as dark as we can so all greys are turned into black */
    filter: brightness(0) drop-shadow(1px 1px 1px var(--black-transparent));
  }

  [data-color-mode="dark"] & {
     /* make it as bright as we can so all greys are turned into white */
    filter: brightness(5) drop-shadow(1px 1px 1px var(--white-transparent));
  }
}

.background-light {
  --app-color: var(--black);
  --app-header-color: var(--black-dark);
}

.background-dark {
  --app-color: var(--white);
  --app-header-color: var(--white-light);
}

.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }
