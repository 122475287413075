.suggestion-item {
  --background-color: var(--app-background);
  --border-color: var(--app-background);
  --metric-color: var(--app-color);
  --highlight-color: var(--app-header-color);

  background: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  min-height: 5rem;

  &.-excluded {
    --border-color: var(--grey-transparent-light);
    --background-color: var(--white-transparent);
  }

  /* when hovering, the hovered floor AND the floor that will be moved to/from will be highlighted */
  &.-highlight {
    --border-color: var(--grey-light);
    --background-color: var(--white-light);
    border-style: dashed;

    [data-color-mode="dark"] & {
      --background-color: var(--white-transparent);
    }
  }

  &:hover,
  &.-highlight:hover {
    --border-color: var(--grey-dark);
    --background-color: var(--white-light);

    [data-color-mode="dark"] & {
      --background-color: var(--white-transparent-dark);
    }
  }

  .suggestion-item-floor {
    border-bottom: 1px solid var(--grey-transparent);
  }

  /** Metrics bar **/
  .suggestion-item-capacity {
    background: var(--white-dark);
    box-shadow: inset 0 0 0 1px var(--grey-transparent-light);
    height: 4rem;


    [data-color-mode="dark"] & {
      --metric-color: var(--black);
      --highlight-color: var(--black-dark);
    }
  }

  .suggestion-item-count {
    box-shadow: inset 0 0 0 1px var(--grey);
    color: var(--metric-color);
    margin: 2px;
  }

  .suggestion-item-opportunity {
    background-color: var(--white);
    box-shadow: inset 0 0 0 1px var(--grey);
    color: var(--metric-color);
    margin: 2px;
  }

    .suggestion-item-reserved {
      border: 1px solid var(--black-transparent);
      box-shadow: 0 0 0 1px var(--white-light), inset 0 0 0 1px var(--white-light);
    }

    .suggestion-item-remaining {
      background-color: var(--white-light);
      box-shadow: inset 0 0 0 1px var(--grey);
      color: var(--metric-color);
      margin: 2px;
    }

  .suggestion-item-empty {
    color: var(--metric-color);
  }
}

  .suggestion-item-column-1 {
    flex: 1 1 16rem;
  }

  .suggestion-item-column-2 {
    flex: 1 1 100%;
    position: relative;
    overflow: hidden;
  }

  .suggestion-item-column-3 {
    flex: 1 1 5rem;
  }

