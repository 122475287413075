.right-now-link {
  display: flex;
  justify-content: space-between;
}

.homepage-card-title {
  display: grid;
  grid-template-rows: 15px 27px 30px;
}

/* preserve space if text is empty */
.homepage-card-title-text span:empty::after,
.homepage-card-title-text:empty::after {
  content: ' ';
  white-space: pre;
}

#gradient {
  animation: skeleton-loading 2s linear infinite;
}

@keyframes skeleton-loading {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}
