  .meeting-distribution:hover .background-striped-grey {
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 1px,
      var(--white-transparent-light) 1px,
      var(--white-transparent-light) 3px
    );
  }

  .meeting-distribution-legend-items {
    transition: opacity 0.3s ease-in;
    opacity: 0;
  }

  .meeting-distribution:hover ~ .meeting-distribution-legend .meeting-distribution-legend-items {
    opacity: 1;
  }

  /** Default - only show stripes **/

  /* Hide the value by default */
  .meeting-distribution-value,
  /* Hide the rainbow background by default */
  .meeting-distribution-scale {
    text-shadow: 1px 1px 1px var(--white-transparent);
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  .meeting-distribution-stripe {
    transition: opacity 0.3s ease-in;
    opacity: 1;
  }

  /** When hovering - hide stripes & show rainbow background **/
  .meeting-distribution:hover .meeting-distribution-value,
  .meeting-distribution:hover .meeting-distribution-scale {
    opacity: 1;
  }
