:root {
  --white-light:  rgb(255, 255, 255);
  --white:        rgb(241, 241, 248);
  --white-dark:   rgb(236, 236, 244);
  --white-darker: rgb(231, 231, 239);

  --grey-light:   rgb(169, 174, 187);
  --grey:         rgb(108, 118, 137);
  --grey-dark:    rgb(87, 100, 113);

  --black-light:  rgb(65, 75, 90);
  --black:        rgb(50, 55, 67);
  --black-dark:   rgb(41, 45, 55);

  --red:          #EB0008;
  --blue:         #0072c6;
  --dark-blue:    #1c1c45;
  --teal:         #4EA5B7;

  --white-transparent-light: rgba(255, 255, 255, .35);
  --white-transparent:      rgba(241, 241, 248, .25);
  --white-transparent-dark: rgba(231, 231, 238, .15);

  --black-transparent-light: rgba(0,0,0, .35);
  --black-transparent:      rgba(0,0,0, .25);
  --black-transparent-dark: rgba(0,0,0, .12);

  --grey-transparent-dark:  hsla(225, 14%, 23%, 0.16);
  --grey-transparent:       hsla(225, 14%, 23%, 0.12);
  --grey-transparent-light: hsla(225, 14%, 23%, 0.08);

  --yellow-dark:      hsla(25, 66%, 79%, 1);
  --yellow:           hsla(25, 66%, 89%, 1);
  --yellow-light:     hsla(25, 66%, 99%, 1);

}

/** White **/
.background-white-light  { background-color: var(--white-light); }
.background-white { background-color: var(--white); }
.background-white-dark  { background-color: var(--white-dark); }

.hover\:background-white-light:hover { background-color: var(--white-light); }
.hover\:background-white:hover { background-color: var(--white); }
.hover\:background-white-dark:hover { background-color: var(--white-dark); }

.border-white-light  { border-color: var(--white-light); }
.border-white { border-color: var(--white); }
.border-white-dark  { border-color: var(--white-dark); }

.color-white-light  { color: var(--white-light); }
.color-white { color: var(--white); }
.color-white-dark  { color: var(--white-dark); }

.hover\:color-white-light:hover { color: var(--white-light); }
.hover\:color-white:hover { color: var(--white); }
.hover\:color-white-dark:hover { color: var(--white-dark); }

/** White transparent **/
.background-white-transparent-light  { background-color: var(--white-transparent-light); }
.background-white-transparent { background-color: var(--white-transparent); }
.background-white-transparent-dark  { background-color: var(--white-transparent-dark); }

.hover\:background-white-transparent-light:hover { background-color: var(--white-transparent-light); }
.hover\:background-white-transparent:hover { background-color: var(--white-transparent); }
.hover\:background-white-transparent-dark:hover { background-color: var(--white-transparent-dark); }

.border-white-transparent-light  { border-color: var(--white-transparent-light); }
.border-white-transparent { border-color: var(--white-transparent); }
.border-white-transparent-dark  { border-color: var(--white-transparent-dark); }

.color-white-transparent-light  { color: var(--white-transparent-light); }
.color-white-transparent { color: var(--white-transparent); }
.color-white-transparent-dark  { color: var(--white-transparent-dark); }

.hover\:color-white-transparent-light:hover { color: var(--white-transparent-light); }
.hover\:color-white-transparent:hover { color: var(--white-transparent); }
.hover\:color-white-transparent-dark:hover { color: var(--white-transparent-dark); }

/** Grey **/
.background-grey-light  { background-color: var(--grey-light); }
.background-grey { background-color: var(--grey); }
.background-grey-dark  { background-color: var(--grey-dark); }

.hover\:background-grey-light:hover { background-color: var(--grey-light); }
.hover\:background-grey:hover { background-color: var(--grey); }
.hover\:background-grey-dark:hover { background-color: var(--grey-dark); }

.border-grey-light  { border-color: var(--grey-light); }
.border-grey { border-color: var(--grey); }
.border-grey-dark  { border-color: var(--grey-dark); }

.color-grey-light  { color: var(--grey-light); }
.color-grey { color: var(--grey); }
.color-grey-dark  { color: var(--grey-dark); }

.hover\:color-grey-light:hover { color: var(--grey-light); }
.hover\:color-grey:hover { color: var(--grey); }
.hover\:color-grey-dark:hover { color: var(--grey-dark); }

/** Black **/
.background-black-light  { background-color: var(--black-light); }
.background-black { background-color: var(--black); }
.background-black-dark  { background-color: var(--black-dark); }

.hover\:background-black-light:hover { background-color: var(--black-light); }
.hover\:background-black:hover { background-color: var(--black); }
.hover\:background-black-dark:hover { background-color: var(--black-dark); }

.border-black-light  { border-color: var(--black-light); }
.border-black { border-color: var(--black); }
.border-black-dark  { border-color: var(--black-dark); }

.color-black-light  { color: var(--black-light); }
.color-black { color: var(--black); }
.color-black-dark  { color: var(--black-dark); }

.hover\:color-black-light:hover { color: var(--black-light); }
.hover\:color-black:hover { color: var(--black); }
.hover\:color-black-dark:hover { color: var(--black-dark); }

/** Black transparent **/
.background-black-transparent-light  { background-color: var(--black-transparent-light); }
.background-black-transparent { background-color: var(--black-transparent); }
.background-black-transparent-dark  { background-color: var(--black-transparent-dark); }

.hover\:background-black-transparent-light:hover { background-color: var(--black-transparent-light); }
.hover\:background-black-transparent:hover { background-color: var(--black-transparent); }
.hover\:background-black-transparent-dark:hover { background-color: var(--black-transparent-dark); }

.border-black-transparent-light  { border-color: var(--black-transparent-light); }
.border-black-transparent { border-color: var(--black-transparent); }
.border-black-transparent-dark  { border-color: var(--black-transparent-dark); }

.color-black-transparent-light  { color: var(--black-transparent-light); }
.color-black-transparent { color: var(--black-transparent); }
.color-black-transparent-dark  { color: var(--black-transparent-dark); }

.hover\:color-black-transparent-light:hover { color: var(--black-transparent-light); }
.hover\:color-black-transparent:hover { color: var(--black-transparent); }
.hover\:color-black-transparent-dark:hover { color: var(--black-transparent-dark); }

/** Grey transparent **/
.background-grey-transparent-light  { background-color: var(--grey-transparent-light); }
.background-grey-transparent { background-color: var(--grey-transparent); }
.background-grey-transparent-dark  { background-color: var(--grey-transparent-dark); }

.hover\:background-grey-transparent-light:hover { background-color: var(--grey-transparent-light); }
.hover\:background-grey-transparent:hover { background-color: var(--grey-transparent); }
.hover\:background-grey-transparent-dark:hover { background-color: var(--grey-transparent-dark); }

.border-grey-transparent-light  { border-color: var(--grey-transparent-light); }
.border-grey-transparent { border-color: var(--grey-transparent); }
.border-grey-transparent-dark  { border-color: var(--grey-transparent-dark); }

.color-grey-transparent-light  { color: var(--grey-transparent-light); }
.color-grey-transparent { color: var(--grey-transparent); }
.color-grey-transparent-dark  { color: var(--grey-transparent-dark); }

.hover\:color-grey-transparent-light:hover { color: var(--grey-transparent-light); }
.hover\:color-grey-transparent:hover { color: var(--grey-transparent); }
.hover\:color-grey-transparent-dark:hover { color: var(--grey-transparent-dark); }

/** Dark blue **/
.background-dark-blue { background-color: var(--dark-blue); }
.hover\:background-dark-blue:hover { background-color: var(--dark-blue); }
.border-dark-blue { border-color: var(--dark-blue); }
.color-dark-blue { color: var(--dark-blue); }
.hover\:color-dark-blue:hover { color: var(--dark-blue); }

/** Blue **/
.background-blue-light  { background-color: var(--blue-light); }
.background-blue { background-color: var(--blue); }
.background-blue-dark  { background-color: var(--blue-dark); }

.hover\:background-blue-light:hover { background-color: var(--blue-light); }
.hover\:background-blue:hover { background-color: var(--blue); }
.hover\:background-blue-dark:hover { background-color: var(--blue-dark); }

.border-blue-light  { border-color: var(--blue-light); }
.border-blue { border-color: var(--blue); }
.border-blue-dark  { border-color: var(--blue-dark); }

.color-blue-light  { color: var(--blue-light); }
.color-blue { color: var(--blue); }
.color-blue-dark  { color: var(--blue-dark); }

.hover\:color-blue-light:hover { color: var(--blue-light); }
.hover\:color-blue:hover { color: var(--blue); }
.hover\:color-blue-dark:hover { color: var(--blue-dark); }

.background-striped-grey {
  background-image: repeating-linear-gradient(
    -45deg,
    var(--black-transparent-light),
    var(--black-transparent-light) 1px,
    var(--grey-transparent) 1px,
    var(--grey-transparent) 3px
  );
}

.background-striped-white-transparent {
  background-image: repeating-linear-gradient(
    -45deg,
    hsla(223deg 4% 95% / 50%),
    hsla(223deg 4% 95% / 50%) 1px,
    var(--white) 1px,
    var(--white) 3px
  );
}

/** Red **/
.color-red { color: var(--red); }

/* Teal */
.color-teal { color: var(--teal); }
