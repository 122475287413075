.bar-chart {
  position: relative;
  display: flex;
  flex-direction: row;
}

.bar-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 1rem 0; /* create space for bottom & top x-axis */
  padding: 0 0.25rem;
  height: 5rem;
  position: relative;
  border-bottom: 1px solid #8a8a8a;
}

.y-axis {
  position: relative;
  margin: 1rem 0; /* same as .bar-content */

  font-size: 0.75rem;
  line-height: 1.2;
  color: #8a8a8a;
}

.y-axis .tick {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.y-axis-left {
  min-width: 9rem;
}

.y-axis-left .tick {
  border-right: 3px solid hsla(204, 100%, 40%, 1);
  padding-right: 0.5rem;
  right: 0;
  text-align: right;
}

.y-axis-right {
  min-width: 6rem;
}

.y-axis-right .tick {
  padding-left: 0.5rem;
}

.y-axis-right .tick-highlight {
  border-left: 3px solid hsla(204, 0%, 40%, 1);
  color: hsla(204, 0%, 40%, 1);
}

.bar-highlight-area {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  background: hsla(204, 100%, 70%, 0.4);
  pointer-events: none;
}

.bar {
  height: 100%;
  box-sizing: border-box;
  flex: 1 0 auto;
  position: relative;
  border: 1px solid #ccc;
  border-bottom: 0;
  font-size: 12px;
  margin: 0 0.25rem;
}

.bar-value {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  text-align: center;
  font-weight: var(--font-weight-regular);
}

.bar-count {
  background: hsl(204, 0%, 80%);
  color: #fff;
  text-indent: -999px;
}

.bar-enough-space .bar-count {
  background: hsl(0, 0%, 65%);
}

.bar-vacant-seats {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 4px,
    hsla(204, 0%, 55%, 0.5) 5px,
    hsla(204, 0%, 55%, 0.5) 5px
  );

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-indent: -999px;
}

.bar-content .line {
  position: absolute;
  left: 0;
  right: 0;
  border-top: 1px solid #8f8f8f;
}

.bar-content .line-solid {
  left: 0.5rem;
}

.bar-content .line-dashed {
  border-top: 1px dashed #8f8f8f;
}

.x-axis {
  position: absolute;
  top: 100%;
  left: 1rem;
  right: 1rem;

  display: flex;
  flex-direction: row;
  color: #6a6a6a;
}

.x-axis-top {
  padding-bottom: 0.25rem;
}
.x-axis-bottom {
  padding-top: 0.25rem;
}

.x-axis .tick {
  font-size: 12px;
  line-height: 1;
  padding: 0.2rem 0 0;
  flex: 1 0 auto;
  text-align: center;
}
