.horizontal-slider {
  --height: 2.5rem;
  --color1: #ccebc5;
  --color2: #a8ddb5;
  --color3: #7bccc4;
  --color4: #43a2ca;
  --color5: #0868ac;

  width: 100%;
  height: var(--height);
  border-radius: 9999px;
  background: var(--white);
  border: 1px solid var(--grey);
  padding: 1px;
}

  .horizontal-slider .track {
    line-height: var(--height);
    text-align: center;
    top: 1px;
    bottom: 1px;
    border-radius: 9999px;
    color: var(--black-dark);

    box-sizing: border-box;
    padding: 0 var(--size-small);
  }

    /* These colors are used for the Room Size Categories control */
    .colored-slider .track-0 { background: var(--color1);
      padding-right: calc(var(--size-small) + var(--height));
    }

    .colored-slider .track-1 { background: var(--color2); }
    .colored-slider .track-2 { background: var(--color3); }
    .colored-slider .track-3 { background: var(--color4); }
    .colored-slider .track-4 {
      background: var(--color5);
      color: var(--white-light);
      padding: 0 calc(var(--size-small) + var(--height));
    }

      /* show a zig-zag line  */
      .horizontal-slider .track-4::after {
        position: absolute;
        right: calc(var(--height) * .5);
        font-size: calc(1.5 * var(--height));
        content: "⦚";
        opacity: 0.25;
      }

  .horizontal-slider .mark {
    box-sizing: border-box;
    width: 1px;
    height: var(--height);
    background: var(--white-transparent);
  }

  .horizontal-slider .thumb {
    box-sizing: border-box;
    cursor: grab;
    height: var(--height);
    width: var(--height);
    line-height: var(--height);
    background: var(--white);
    border: 1px solid var(--grey);
    border-radius: 9999px;
    font-size: var(--font-size-7);
    font-weight: var(--font-weight-header);
    text-align: center;
    overflow: hidden;
  }

  .horizontal-slider .thumb.active,
  .horizontal-slider .thumb:focus-visible {
    outline: 0;
    border-color: var(--color5);
    box-shadow: 0 0 2px 1px var(--color4);
  }
