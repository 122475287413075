
.booking-details {
  --background: var(--app-background);
  --color: var(--app-header-color);
  --chart-height: 7rem;
  height: var(--chart-height);
}

/** 
  Chart wrapper & axes
**/

.booking-chart {
  position: relative;
  min-width: 15rem;
  max-width: 100%; height: 100%;
  box-sizing: border-box;
  display: inline-block;
  padding: var(--size-large) var(--size-large) 0 0;
}

  .booking-chart-label-x {
    position: absolute;
    top: 0; left: 0; right: var(--size-large);
    line-height: var(--size-large);
    text-align: center;
  }

    .booking-chart-border-x {
      position: absolute;
      top: 50%; left: 0; right: 0;
      border-top: 1px solid var(--grey-transparent);
    }

    .booking-chart-label-text {
      position: relative;
    }

  .booking-chart-label-y {
    position: absolute;
    right: 0;
    top: var(--size-large);
    bottom: 0;
    white-space: nowrap;
    writing-mode:tb-rl;
    line-height: var(--size-large);
    text-align: center;
  }

    .booking-chart-border-y {
      position: absolute;
      top: 0;
      right: 50%;
      bottom: 0;
      border-right: 1px solid var(--grey-transparent);
    }

    .booking-chart-label-y .booking-chart-label-text {

    }

/* Bars */
.timeline {
  background: var(--white-transparent-light);
  max-width: 100%;
  height: 100%;
}

  .timeline-graph {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
  }

    /* Bar */
    .timeline-datum {
      flex: 1;
      display: flex;
      flex-direction: column;

      position: relative;
      margin: 0 1px 0 0;
      min-width: .5rem;
      /*max-width: .5rem;*/
    }

    .timeline-values {
      flex: 1 0 100%;
      display: flex;
    }

    .timeline-unstacked .timeline-values {
      flex-direction: row;
      align-items: flex-end;
    }

    .timeline-stacked .timeline-values {
      flex-direction: column;
      justify-content: flex-end;
    }

      .timeline-value {
        position: relative;
        font-size: 10px;
        text-align: center;
        background: var(--grey);
        border: 1px solid var(--black-transparent);
      }

      .timeline-unstacked .timeline-value {
        flex: 1 1 100%;
      }

