.animated {
  animation-duration: .3s;
  animation-fill-mode: both;
}

.animated.fast {
  animation-duration: .18s;
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeOut {
  animation-name: fadeOut;
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

.popIn {
  animation-name: popIn;
}

.rotatable {
  transition: transform 0.15s;
}

.rotatable.rotate-180 {
  transform: rotate(-180deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 0;
    height: 0;
    margin: 0;
  }
}

@keyframes lineScalePulseOut {
  0%,
  40%,
  100% {
      transform: scaleY(0.4);
  }

  20% {
      transform: scaleY(1);
  }
}

@keyframes popIn {
  from {
    opacity: 0.5;
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0.5;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
