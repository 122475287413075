html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  cursor: default;

  font-family: var(--font-family-regular);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-root);
  line-height: var(--line-height-medium);
}

body {
  margin: 0; /* reset */
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/** reset all button-like elements **/
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

p,
ul,
ol {
  margin: 0 0 var(--size-medium);
}

ul, ol {
  padding-left: var(--size-medium);
}

nav ol, nav ul {
  list-style: none;
}

li {
  margin: 0;
}

code, kbd, pre, samp {
  font-family:
    /* macOS 10.10+ */ Menlo,
    /* Windows 6+ */ Consolas,
    /* Android 4+ */ Roboto Mono,
    /* Ubuntu 10.10+ */ Ubuntu Monospace,
    /* KDE Plasma 5+ */ Noto Mono,
    /* KDE Plasma 4+ */ Oxygen Mono,
    /* Linux/OpenOffice fallback */ Liberation Mono,
    /* fallback */ monospace;
}

svg:not([fill]) {
  fill: currentColor;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-header);
  font-weight: var(--font-weight-header);
  margin: 0 0 var(--size-small) 0; /* reset */
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

/** https://css-tricks.com/snippets/css/prevent-superscripts-and-subscripts-from-affecting-line-height/ **/
sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

sub {
  top: 0.4em;
}

table {
  border: 0;
  border-spacing: 0;
}

th {
  text-align: left;
  font-family: var(--font-family-header);
  font-weight: var(--font-weight-header);
  vertical-align: top;
}

td {
  vertical-align: top;
}

hr {
  display: block;
  width: 100%;
  margin: var(--size-medium) 0;
  border: 0;
  height: 1px;
  border-width: 1px 0;
}

figure {
  margin: var(--size-medium);
}