@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300 900;
  font-stretch: 100%;
  src: url("./fonts/OpenSans-VariableFont_wdth,wght.ttf")
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300 900;
  font-stretch: 100%;
  src: url("./fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf")
}

:root {
  --font-family-regular: "Open Sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-header: var(--font-family-regular);
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

  --font-size-root: 12px;
  --font-size-1: calc(var(--font-size-root) + 12px);
  --font-size-2: calc(var(--font-size-root) + 6px);
  --font-size-3: calc(var(--font-size-root) + 4px);
  --font-size-4: calc(var(--font-size-root) + 2px);
  --font-size-5: calc(var(--font-size-root) + 1px);
  --font-size-6: var(--font-size-root);
  --font-size-7: calc(var(--font-size-root) - 1px);
  --font-size-8: calc(var(--font-size-root) - 2px);
  --font-size-9: calc(var(--font-size-root) - 3px);

  --font-weight-thin: 200;
  --font-weight-regular: 400;
  --font-weight-header: 600;

  --line-height-large: 1.4;
  --line-height-medium: 1.25;
  --line-height-small: 1.125;
  --line-height-extra-small: 1;

  --letter-spacing-1: 0;
  --letter-spacing-2: 0;
  --letter-spacing-3: 0;
  --letter-spacing-4: 0;
}

@media (min-width: 1600px) {
  :root {
    --font-size-root: 13px;
  }
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--app-header-color);
}

h1, .font-size-1 {
  font-size: var(--font-size-1);
  line-height: var(--line-height-small);
}

h2, .font-size-2 {
  font-size: var(--font-size-2);
  line-height: var(--line-height-small);
}

h3, .font-size-3 {
  font-size: var(--font-size-3);
  line-height: var(--line-height-medium);
}

h4, .font-size-4 {
  font-size: var(--font-size-4);
  line-height: var(--line-height-medium);
}

h5, .font-size-5 {
  font-size: var(--font-size-5);
  line-height: var(--line-height-medium);
}

h6, .font-size-6 {
  font-size: var(--font-size-6);
  line-height: var(--line-height-medium);
}

small, .font-size-7 {
  font-size: var(--font-size-7);
  line-height: var(--line-height-small);
}

.font-size-8 {
  font-size: var(--font-size-8);
  line-height: var(--line-height-small);
}

.font-size-9 {
  font-size: var(--font-size-9);
  line-height: var(--line-height-small);
}

.font-hairline,
.font-thin,
.font-light { font-weight: var(--font-weight-thin); }
.font-normal,
.font-medium { font-weight: var(--font-weight-regular); }
.font-semibold,
.font-bold,
.font-extrabold,
.font-black { font-weight: var(--font-weight-header); }

.letter-spacing-1 { letter-spacing: var(--letter-spacing-1); }
.letter-spacing-2 { letter-spacing: var(--letter-spacing-2); }
.letter-spacing-3 { letter-spacing: var(--letter-spacing-3); }
.letter-spacing-4 { letter-spacing: var(--letter-spacing-4); }

.line-height-extra-small {
  line-height: var(--line-height-extra-small);
}

.line-height-small {
  line-height: var(--line-height-small);
}

.line-height-medium {
  line-height: var(--line-height-medium);
}

.line-height-large {
  line-height: var(--line-height-large);
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.detail {
  opacity: 0.6;
}

.word-break-all {
  word-break: break-word;
  hyphens: auto;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.label,
.title,
.fat-title,
.small-title {
  color: var(--label-color);
  font-size: var(--font-size-8);
  font-weight: var(--font-weight-header);
  letter-spacing: var(--letter-spacing-3);
  text-transform: uppercase;
}

.title { font-size: var(--font-size-6); }
.small-title { font-size: var(--font-size-8); }
.fat-title { font-weight: bold; }

.sticky-title {
  font-size: var(--font-size-6);
  font-weight: var(--font-weight-header);
  letter-spacing: var(--letter-spacing-3);
  text-transform: uppercase;
  position: sticky;
  top: 0;
  padding: var(--size-medium);
  background: var(--app-background);
  box-shadow: 0px 4px 14px -14px var(--black);
  color: var(--label-color);
  filter: opacity(0.95);
  z-index: 1;
}

strong,
em,
.highlight {
  color: var(--highlight-color);
}

/* Facet labels in Observable.Plot */
[aria-label="y-axis label"],
[aria-label="x-axis label"] {
  color: var(--label-color);
}