/** Wrapper HTML that contains the SVG & the text **/
.progress-metric {
  color: var(--black);

  [data-color-mode="dark"] & {
    color: var(--white-light);
  }
}

/** Wrapper SVG **/
.progress-circle {
  --background: var(--white-light);
  --border: var(--white);
  --progress-bar: var(--black-transparent);
  --progress-bar-active: var(--black);

  [data-color-mode="dark"] & {
    --background: var(--black);
    --border: var(--black-light);
    --progress-bar: var(--grey-dark);
    --progress-bar-active: var(--white);
  }
}

  /** Setup 1 circle that contains the background for the text **/
  .progress-circle-circle {
    fill: var(--background);

  }

  /** This is the complete path for the line **/
  .progress-circle-background {
    fill: var(--progress-bar);
    stroke-width: .05;
    stroke: var(--border);
  }

.progress-stroke-foreground {
    stroke-width: .05;
    stroke: var(--border);
    fill: var(--progress-bar-active);
}

.pill-bar {
  gap: var(--size-small);

  .pill-bar-pill {
    background: var(--white-light);
    border: 1px solid var(--white-dark);

    [data-color-mode="dark"] & {
      background: var(--black-light);
      border-color: var(--black-transparent-light);
    }
  }

  .pill-bar-value {
    width: 3rem;
  }
}