table {
  --th-background: transparent;
  --th-border: var(--grey-transparent);
  --th-color: var(--label-color);
  --td-background: transparent;
  --td-border: transparent;
  --td-color: var(--app-color);
  --highlight-color: var(--black-dark);

  caption {
    color: var(--th-color);
    font-size: var(--font-size-8);
    font-weight: var(--font-weight-header);
    letter-spacing: var(--letter-spacing-3);
    text-transform: uppercase;
    text-align: left;
    margin-bottom: var(--size-small);
  }
}

  th {
    background: var(--th-background);
    border: 1px solid var(--th-border);
    color: var(--th-color);

    font-size: var(--font-size-8);
    font-weight: var(--font-weight-header);
    letter-spacing: var(--letter-spacing-3);
    text-transform: uppercase;

    .detail {
      font-weight: var(--font-weight-regular);
      text-transform: none;
    }
  }

  th.clickable:hover {
    --th-border: var(--black);
  }

  th + th:not(:hover) {
    border-left-color: transparent;
  }

.table-transparent {
  --th-background: transparent;
  /* --th-border: transparent; */
  --th-color: var(--label-color);
  --td-background: transparent;
  --td-border: transparent;
  --td-color: var(--app-color);
}

  .table-transparent td,
  .table-transparent th {
    border: 0;
  }

  .table-transparent thead th {
    border-bottom: 1px solid var(--th-border);
  }

  .table-transparent tfoot th {
    border-top: 1px solid var(--th-border);
  }

  .table-transparent tbody th:first-of-type,
  .table-transparent tfoot th:first-of-type {
    border-right: 1px solid var(--th-border);
  }

  .table-transparent tbody th:last-of-type,
  .table-transparent tfoot th:last-of-type {
    border-left: 1px solid var(--th-border);
  }

.table-layout-fixed {
  table-layout: fixed;
}

.table-simple {
  --td-border: var(--grey-transparent);
}

  .table-simple th,
  .table-simple td {
    padding: var(--size-small) 0 var(--size-extra-small);
    border-bottom: 1px solid var(--td-border);
  }

  .tooltip-container .table-simple th {
    border-bottom: 1px solid var(--tooltip-hr);
  }

.table-advanced {
  --th-background: var(--white-light);
  --td-background: var(--white);
  --td-color: var(--black);
  --top-header-row-background: var(--grey-transparent);
  --top-header-row-color: var(--app-header-color);
}

/** Stick table header **/
.table-advanced thead tr:only-child,
.table-advanced thead tr:nth-child(2) {
  position: sticky;
  top: calc(var(--size-medium) * -1);
  z-index: 1;
}

/** Header groups - if there are multiple rows, the top row should be styled differently **/
.table-advanced thead tr:first-child:not(:only-child) {
  --th-background: var(--top-header-row-background);
  --th-color: var(--top-header-row-color);

  th {
    --th-color: var(--label-color);
    font-size: var(--font-size-6);
    text-transform: none;
  }

  th:empty + th {
    border-left-color: var(--th-border);
  }
}

/** Column Headers **/
.table-advanced thead th {
  transition: all 0.15s ease-in-out;
}

.table-advanced thead th:empty {
  --th-border: transparent;
  --th-background: transparent;
}

  /** Sorting icon & style **/
  [role="columnheader"] .icon-sort-direction,
  [role="columnheader"] .icon-sortable {
    transition: transform 0.15s ease-in;
  }

  .icon-sortable {
    opacity: 0.25;

    &.icon-sort-direction {
      opacity: 1;
    }
  }

  .-sort-asc .icon-sort-direction {
    transform: rotate(-180deg);
  }

  .-sort-asc,
  .-sort-desc {
    transition: box-shadow 0.15s ease-in;
  }

  .-sort-asc {
    box-shadow: inset 0 5px 0 0 var(--grey);
  }

  .-sort-desc {
    box-shadow: inset 0 -5px 0 0 var(--grey);
  }

/** Cells **/
.table-advanced td {
  background: var(--td-background);
  border: 1px solid var(--td-border);
  border-top: 0;
  color: var(--td-color);
  box-sizing: border-box;
  transition: all .18s cubic-bezier(.78, .14, .15, .86);
}

  .table-advanced td:not(:last-child) {
    border-right: 0;
  }

  .table-advanced td:not(:first-child) {
    border-left: 0;
  }

/** Expandable grouped rows **/
.table-advanced .row-is-expanded {
  --td-background: var(--white-light);
}

.table-advanced .row-not-expanded {
  --td-background: var(--white);
}

.table-advanced .row-not-grouped {
  --td-background: var(--white-dark);
}

/** Expandable sub rows **/
.table-advanced .row-sub-expanded {
  --td-background: var(--grey-light);
  --td-border: var(--grey);
}

/** Striping **/
.table-striped .row {
  --td-border: var(--grey-transparent);
}

/** Highlighting **/
.table-highlight .row:hover {
  --td-background: var(--white-light);
}

/** Primary table (e.g. Portfolio Insights) **/
.table-white .row {
  --th-background: var(--white-light);
  --td-background: var(--white);
  --td-color: var(--black);
}

.table-white .row-expandable {
  --td-background: var(--white-light);
}

.table-white .row-expanded {
  --td-border: var(--grey-transparent);
}