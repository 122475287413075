.spinner-bars-medium {
  --height: 34px;
  --width: 4px;
  --margin: 2px;
}

.spinner-bars-small {
  --height: 1.75rem;
  --width: 2px;
  --margin: 1px 1px 1px 2px;  
}

  .spinner-bars > div {
    background-color: var(--app-color);
    height: var(--height);
    width: var(--width);
    border-radius: var(--margin);
    margin: var(--margin);
    animation-fill-mode: both;
    display: inline-block;
    animation: lineScalePulseOut 1.2s infinite ease-in-out;
  }

    .spinner-bars > div:nth-child(1) {
      animation-delay: -1.1s;
    }

    .spinner-bars > div:nth-child(2) {
      animation-delay: -1s;
    }

    .spinner-bars > div:nth-child(3) {
      animation-delay: -.9s;
    }

    .spinner-bars > div:nth-child(4) {
      animation-delay: -.8s;
    }

    .spinner-bars > div:nth-child(5) {
      animation-delay: -.7s;
    }

.spinner-bars.color-white > div {
  background-color: var(--white)
}
