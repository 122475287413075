.message {
  --message-background: var(--white-light);
  --message-border: var(--grey-transparent);
  --app-header-color: var(--black);
  --app-color: var(--grey);
  --highlight-color: var(--black-dark);

  background: var(--message-background);
  border: 1px solid var(--message-border);
  border-radius: var(--border-radius);
  color: var(--app-color);
}

  .message-content > p:only-child,
  .message-content > p:last-child {
    margin-bottom: 0;
  }

.message-info,
.message-explanation {

}
.message-alert,
.message-warning {
  --message-background: var(--yellow);
}

.message-error {
  --app-header-color: var(--red);
}
.message-error .message-content {
  padding-top: 10px;
  font-size: var(--font-size-5);
}
.message-error .message-content .card-title {
  color: var(--grey);
}
.message-error .message-content .card-header {
  padding-left: 0
}

.badge {
  text-align: center;
  background-color: var(--white-light);
  border: 1px solid var(--black-transparent-dark);
  border-radius: 500px;
  color: var(--grey-dark);
  display: inline-block;
  line-height: 1rem;
  padding: var(--size-extra-small);
  margin: 0 var(--size-extra-small);
  min-width: 1rem;
  min-height: 1rem;
}

  .badge svg {
    display: inline-block;
  }