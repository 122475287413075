/** Heatmap layout **/

.app-content:has(.heatmap-layout) {
  padding-bottom: 0;
}

.heatmap-layout {
  width: 100%;
  height: 100%;
  margin-bottom: -1rem;

  display: grid;
  grid-template-columns: 1fr 30ch;
  grid-template-rows: auto 1fr 14rem;
  grid-template-areas:
    "header sidebar"
    "content sidebar"
    "footer footer";
  gap: var(--size-medium) var(--size-large);
  transition: grid-template .36s ease-in-out;

  &.hide-chart,
  &:has(.heatmap-footer:empty) {
    grid-template-rows: auto 1fr 0;
  }

  &.hide-sidebar {
    grid-template-columns: 1fr 0ch;
  }

  .heatmap-header {
    grid-area: header;
    display: flex;
    gap: var(--size-medium);
  }

    .heatmap-title {
      flex: 1;
    }

    .heatmap-controls {
    }

  .heatmap-content {
    grid-area: content;
    overflow-y: auto;
  }

    .heatmap-layer-floorplan-controls {
      position: absolute;
      top: var(--size-medium);
      right: var(--size-medium);
    }

  .heatmap-sidebar {
    position: relative;
    grid-area: sidebar;
    overflow-y: auto;
    width: 30ch;
    padding-top: var(--size-small);

    [data-color-mode="dark"] & .table-advanced {
      --th-background: var(--black-dark);
      --td-background: var(--black);
      --td-color: var(--white);

      .row:hover {
        --td-background: var(--white-transparent-light);
      }
    }

    .button-pills .button {
      flex: 1;
    }

    .hide-sidebar & > * {
      pointer-events: none;
      transition: opacity .36s ease-in-out;
      opacity: 0;
    }
  }

    .heatmap-sidebar-toggle {
      grid-area: sidebar;
      width: var(--size-large);
      z-index: 1;
      display: grid;
      transform: translateX(calc(var(--size-large) * -1));
      position: relative;

      .heatmap-sidebar-toggle-gutter {
        background: var(--app-header-border);
        border-radius: var(--size-extra-small);
        transition: background .18s ease-in-out;
        height: 100%;
        width: var(--size-small);
        position: absolute;
        left: 0; right: 0;
        margin: auto;
        pointer-events: none;
      }

      .heatmap-sidebar-toggle-button {
        align-self: center;
        justify-self: center;
        transform: translateX(-50%); /* move 50% of its rendered width to the left */
        margin-left: calc(var(--size-large) / 2); /* place it  */
        opacity: 0;
        transition: opacity .18s ease-in-out;
        padding: .5rem;
        cursor: pointer;

        .button {
          filter: drop-shadow(0px 4px 4px var(--app-header-shadow));
          --border-radius: 1000px;
          --button-background: var(--app-background);
          --button-border: var(--grey);
          width: 2.5rem;
          height: 2.5rem;
        }

        .button-icon .icon {
          padding-left: var(--size-small);
          padding-right: var(--size-small);

          /* nudge the chevron icon so it's center-aligned */
          margin-right: -1px;
          margin-left: 1px;

          .hide-sidebar & {
            margin-left: -1px;
            margin-right: 1px;
          }
        }
      }

      &:hover {
        .heatmap-sidebar-toggle-gutter {
          background: var(--app-header-shadow);
        }

        .heatmap-sidebar-toggle-button {
          opacity: 1;
        }
      }

    }

  .heatmap-footer {
    position: relative;
    grid-area: footer;
    background: var(--white);
    border-top: 1px solid var(--app-header-border);
    padding: var(--size-medium) var(--size-medium) var(--size-small) var(--size-small);

    .plot-chart-figure {
      margin: 0;
    }

    .plot-chart {
      --plot-background: transparent;
    }

    [data-color-mode="dark"] & {
      background: var(--black-dark);
      color: var(--grey-light);
    }
  }
}

/** Heatmap - building(s) with floors overview **/
.heatmap-building {
  .sticky-title {
    padding: var(--size-medium) 0 var(--size-small);
    margin: var(--size-large) 0;

    &:first-child {
      margin-top: 0;
    }
  }

  &:hover .sticky-title {
    color: var(--black);
  }
}

  .heatmap-floors {
    display: grid;
    gap: var(--size-medium);
    grid-template-columns: repeat(
      auto-fit,
      minmax(20rem, .5fr)
    );
  }

/** Heatmap - floorplan elements **/
.heatmap-layers {
  --heatmap-background: var(--app-background);
  --heatmap-color: var(--black-dark);
  --heatmap-color-outline: var(--white-light);
  --heatmap-border: var(--black);
  --heatmap-metric-background: var(--black);
  --heatmap-metric-color: var(--white);
  --heatmap-metric-shadow: var(--white-light);

  position: relative;
  background: var(--heatmap-background);
  border-radius: var(--border-radius);
  overflow: hidden;
}

[data-color-mode="dark"] .heatmap-layers {
  --highlight-color: var(--black-dark);
}

  .heatmap-layers-zoom {
    /* NOTE: this div will create its own stacking context because it uses `transform`*/
    transform-origin: top left;
  }

    .heatmap-layer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    /** Zones layer **/
    .heatmap-layer-zones {

      .heatmap-layer-zone {
        stroke-width: 5px;
        transition: all .18s ease-in-out;
        opacity: .75;

        .hide-colors & {
          color: transparent;
          fill: transparent;
          stroke: var(--heatmap-border);
          stroke-width: 1px;

          &.-wifilayer {
            color: var(--grey-light);
            fill: var(--grey-light);
          }

          &.-sensorlayer {
            color: var(--white-dark);
            fill: var(--white-dark);
          }
        }

        .empty-zones-grey &.-empty {
          color: var(--grey-light);
          fill: var(--grey-light);
          stroke: var(--grey-dark);
        }

        &.-no-data {
          fill: url(#transparent-stripes);
          stroke: var(--black-transparent);

          &.-desk {
            color: var(--grey-transparent);
            fill: var(--grey-transparent);
            stroke: var(--grey);
          }
        }

        &.-wifilayer {
          stroke-width: 9px;
        }

        &.-sensorlayer {
          stroke-width: 3px;
        }

        &.-active,
        &.-focus,
        &:hover {
          stroke: var(--heatmap-border);

          .hide-colors & {
            stroke: var(--blue);
          }

          .zoom-factor-1 & { stroke-width: 15px; }
          .zoom-factor-2 & { stroke-width: 13px; }
          .zoom-factor-3 & { stroke-width: 11px; }
          .zoom-factor-4 & { stroke-width: 9px; }
          .zoom-factor-5 & { stroke-width: 7px; }
        }

        .has-focus &:not(.-focus) {
          fill-opacity: .8;
          stroke-opacity: .8;
        }

        &.-intersect-parent,
        &.-intersect-children {
          stroke: var(--black-dark);

          .hide-colors & {
            stroke: var(--blue);
          }
        }
      }
    }

    .heatmap-layer-floorplan-base {
      fill: var(--heatmap-background);
    }

    .heatmap-layer-floorplan-background,
    .heatmap-layer-floorplan-foreground {
      transition: opacity .2s;
      opacity: .1;
      pointer-events: none;

      &.active {
        opacity: .25;
      }
    }

    .heatmap-layer-floorplan-background {
      [data-color-mode="light"] & {
        filter: grayscale(100%) brightness(0) drop-shadow(1px 1px 1px var(--white));
      }

      [data-color-mode="dark"] & {
        filter: grayscale(100%) brightness(5) drop-shadow(1px 1px 1px var(--black-transparent));
      }
    }

    .heatmap-layer-floorplan-foreground {
      [data-color-mode="light"] & {
        filter: grayscale(100%) brightness(0) drop-shadow(1px 1px 1px var(--white));
      }

      [data-color-mode="dark"] & {
        filter: grayscale(100%) brightness(0) drop-shadow(1px 1px 1px var(--white));
      }
    }

    .heatmap-layer {
      .zone-label {
        text-align: center;
        transform-origin: center center;
        position: absolute;
        transition: all .18s ease-out;

        &.-active {
          z-index: 1;
        }

        .zone-name {
          background: var(--heatmap-metric-background);
          color: var(--heatmap-metric-color);
          padding: 0 var(--size-extra-small);
          margin-bottom: var(--size-extra-small);

          .-active & {
            opacity: 1;
          }
        }

        .zone-metric {
          display: inline-block;
          text-shadow:
            -1px 0 var(--heatmap-color-outline),
            0 1px var(--heatmap-color-outline),
            1px 0 var(--heatmap-color-outline),
            0 -1px var(--heatmap-color-outline);
          padding: 0 var(--size-extra-small);

          .-desk &,
          .-empty & {
            opacity: 0;
          }

          .-active & {
            opacity: 1;
            background: var(--heatmap-metric-background);
            color: var(--heatmap-metric-color);
            text-shadow: 0 0 var(--heatmap-metric-background);
          }
        }
      }

      .zone-name,
      .zone-metric {
        opacity: 0;
        transition: opacity 0.18s ease-in-out;
      }

      &.show-names .zone-name,
      &.show-metrics .zone-metric {
        opacity: 1;
      }
    }

/** Date-time picker rendered on top of heatmap **/
.click-bar {

  &:hover{
    background: var(--app-header-border);
  }

  &.-active {
    background: var(--app-header-border);
  }
}
