/* plot with legend & chart - e.g. Compare Spaces */
.plot-chart-figure {
  margin: 0;

  /* legend */
  .plot-chart-ramp {
    height: fit-content;
    margin-top: calc(-1 * var(--size-large));
    position: absolute;
    top: 0;
    right: 0;
    background: var(--card-background);
  }

  /* swatches */
  .plot-chart-swatches {
    .plot-chart-swatch {
      margin: 0 var(--size-medium) var(--size-extra-small) 0;
    }
  }

  /* chart */
  .plot-chart {}
}

.plot-chart {

  [aria-label="tip"] {
    --plot-background: var(--white-light);
    color: var(--black);
    /* fill: var(--black); */
    stroke: var(--black-light);
  }
}

.legend-ramp {
  display: inline-block;

  .selected-title & {
    float: right;
    font-weight: normal;
  }

  .tick line {
    /* color: var(--black-transparent); */
    mix-blend-mode: overlay;
  }

  .tick text {
    text-anchor: middle;
    font-size: var(--font-size-9);
  }
}

.track {
  stroke-width: 1;
  stroke: var(--grey-light);
}

.track-inset {
  stroke-width: 1;
  stroke: var(--grey-light);
}

.track-overlay {
  stroke-width: 10;
}

.time_line .tick {
  display: none;
}

.slider .parameter-value .handle {
  outline: none;
}