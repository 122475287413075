.icon.clickable {
  transition: all .18s cubic-bezier(.78, .14, .15, .86);
  font-style: normal;
  opacity: 0.65;
}

.icon.clickable:hover {
  opacity: 1;
}

.icon.not-clickable,
.icon.not-clickable:hover {
  opacity: 0.35;
}


/** Individual icons **/
.icon-clear {
  --background-color: var(--white-light);
  --color: var(--black-dark);
  --shadow-color: var(--app-header-shadow);

  position: absolute;
  top: -.5rem;
  right: -.25rem;
  z-index: 1;
  text-align: center;
  background: var(--background-color);
  color: var(--color);
  border-radius: 1000px;
  width: 1rem;
  height: 1rem;
  line-height: 1;

  [data-color-mode="dark"] & {
    --background-color: var(--black-light);
    --color: var(--white);
  }

  & > svg {
    margin-top: -2px;
  }

  &.clickable {
    opacity: 0;
    box-shadow: 0 0 0 1px var(--shadow-color);
    font-size: var(--font-size-8);
    padding: 2px;

    [data-color-mode="dark"] & {
      --background-color: var(--black-dark);
      --shadow-color: var(--white);
      --color: var(--white-light);
    }

    & > svg {
      margin-top: 0;
    }
  }

  &:focus-within,
  .button-dropdown:focus-within &,
  .button-dropdown:hover &,
  .message-closable &.clickable,
  .card-closable:hover &,
  .card-closable:focus-within & {
    opacity: 1;
  }

  &:focus,
  &.clickable:hover,
  .button-dropdown:hover &:hover,
  .message-closable:hover &:hover,
  .card-closable:hover &:hover {
    --color: var(--black);
    --shadow-color: var(--black);
    outline: 0;

    [data-color-mode="dark"] & {
      --background-color: var(--black-light);
      --shadow-color: var(--white);
      --color: var(--white-light);
    }
  }

  .card-closable &,
  .message-closable & {
    font-size: var(--font-size-7);
    padding: var(--size-extra-small);
  }
}

.button-dropdown,
.card-closable .card-header {
  position: relative;
}

