.tooltip-container {
  --tooltip-background: var(--black);
  --tooltip-color: var(--white);
  --tooltip-highlight-color: var(--white-light);
  --tooltip-border: var(--black);
  --tooltip-shadow: var(--black-transparent);
  --tooltip-hr: var(--grey-dark);

  --app-color: var(--tooltip-color);
  --app-header-color: var(--tooltip-highlight-color);
  --highlight-color: var(--tooltip-highlight-color);
  --label-color: var(--tooltip-color);

  background-color: var(--tooltip-background);
  color: var(--tooltip-color);
  border-radius: 3px;
  border: 1px solid var(--tooltip-border);
  box-shadow: 0 2px 4px var(--tooltip-shadow);
  padding: 0.4rem;

  z-index: 1010;

  max-height: 80vh;
}

[data-color-mode="dark"] .tooltip-container {
  --tooltip-background: var(--white-light);
  --tooltip-color: var(--black);
  --tooltip-highlight-color: var(--white-light);
  --tooltip-border: var(--grey-transparent);
  --tooltip-shadow: var(--black-transparent);
  --tooltip-hr: var(--grey-light);
  --app-header-color: var(--black);
}

.tooltip-container-medium {
  max-width: 20rem;
}

.tooltip-container-large {
  min-width: 20rem;
  max-width: 40rem;
}

.tooltip-hidden {
  visibility: hidden;
}

.tooltip-visible {
  visibility: visible;
}

.tooltip-icon {
  color: var(--grey-light);
  opacity: 0.5;
}

  .tooltip-icon:hover {
    opacity: 1;
  }

  .trigger .tooltip-icon {
    transition: opacity 0.15s ease-in;
    opacity: 0;
  }

  .trigger:hover .tooltip-icon {
    opacity: 1;
  }

/** **/
/* .tooltip-container table,
.tooltip-container td,
.tooltip-container th {
  border-color: var(--tooltip-hr);
}
 */