:root {
  --input-background:  var(--app-background);
  --input-border:  var(--white-dark);
  --input-color: var(--app-color);
}

[data-color-mode="dark"] {
  --input-background: var(--black-dark);
  --input-border:  var(--black-light);
  --input-color: var(--white);
}

input {
  box-sizing: border-box;
  background: var(--input-background);
  border: 1px solid var(--input-border);
  color: var(--input-color);
  border-radius: var(--border-radius);
  font-family: var(--font-family-regular);
  font-size: var(--font-size-root);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-extra-small);
  max-width: 100%;
  margin: 0;
}

input::placeholder {
  color: var(--input-color);
  opacity: 0.5;
}

input[type=checkbox],
input[type=radio] {
}

input[type=text] {
  width: 100%;
  padding: var(--size-small) var(--size-extra-small);
}

input[type=text]:hover,
input[type=search]:hover {
  border-color: var(--black-light);
}

input[type=text]:focus,
input[type=search]:focus,
input[type=number]:focus {
  border-color: var(--white-light);
  background: var(--white-light);
  color: var(--black-dark);
}

input[type=number] {
  box-sizing: border-box;
  margin: 0 var(--size-small);
  padding: var(--size-small) 6px;

  font: inherit;
  font-weight: var(--font-weight-header);
  width: 4rem;
  text-align: right;
}

/** Form elements **/
input[type=number].input-number-large {
  font-size: var(--font-size-3);
  padding: var(--size-medium);
}

label {
  font-family: var(--font-family-header);
}

input[inputmode="numeric"][pattern="[0-9]*"] {
  min-width: 2.5rem;
}

.label-transparent {
  display: inline-block;
  transition: background 0.3s ease;
}

.label-transparent:hover {
  background: var(--grey-transparent);
  cursor: text;
}

.error-field {
  outline: 1px dotted var(--red);
}
